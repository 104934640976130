<template>
  <vuestic-widget v-if="user">
    <template slot="header">
      <span class="title">Report Setting</span>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="reportForm">
      <form @submit.prevent="handleSubmit(save)" autocomplete="off">
        <div class="mt-2">
          <label class="lelabel">Emails</label>
          <div class="row">
            <div class="col-md-12" v-for="(email, index) in report.emails" :key="index">
              <div class="inputEmail">
                <text-input :name="`emails.${index}`" :validate="'required|email'" v-model="report.emails[index]" placeholder="Email" />
                <button type="button" @click="removeEmail(index)" class="btn btn-icon btn-danger"><i class="fa fa-trash"></i></button>
              </div>
            </div>
            <div class="col-md-12 d-flex justify-content-start">
              <button :disabled="invalid" type="button" @click="addEmail" class="btn btn-primary btn-sm"><i class="fa fa-plus"></i> add more</button>
            </div>
          </div>
        </div>

        <div class="row mt-2 mb-2">
          <div class="col">
            <label class="lelabel">Schedule</label>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="scheduleDaily" value="Daily" v-model="report.schedules">
              <label for="scheduleDaily">
                <span class="abc-label-text">Daily</span>
              </label>
            </div>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="scheduleWeekly" value="Weekly" v-model="report.schedules">
              <label for="scheduleWeekly">
                <span class="abc-label-text">Weekly</span>
              </label>
            </div>
            <div class="checkbox abc-checkbox abc-checkbox-primary">
              <input type="checkbox" name="schedule" id="scheduleMonthly" value="Monthly" v-model="report.schedules">
              <label for="scheduleMonthly">
                <span class="abc-label-text">Monthly</span>
              </label>
            </div>
          </div>
        </div>
        <button :loading="loading" :disabled="invalid" class="btn btn-primary">Update</button>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>

<script>
export default {
  data () {
    return {
      report: {
        emails: [],
        schedules: []
      }
    }
  },

  mounted () {
    this.report.emails = JSON.parse(JSON.stringify(this.user.business.report_emails))
    this.report.schedules = JSON.parse(JSON.stringify(this.user.business.report_schedules))
  },

  methods: {
    addEmail() {
      return this.report.emails.push('')
    },

    removeEmail (index) {
      this.report.emails.splice(index, 1)
    },

    save () {
      this.$store.dispatch('business/updateReport', this.report)
        .then((res) => {

        })
        .catch((err) => {
          if (err.data && err.data.errors) {
            this.$refs.reportForm.setErrors(err.data.errors)
          }
        })
    }
  },

  computed: {
    loading () {
      return this.$store.state.business.loading
    },

    user () {
      return this.$store.state.auth.user
    }
  }
}
</script>

<style scoped lang="scss">
  legend {
    padding-top: 0;
  }
  select.form-control{
    width: 100% !important;
    border-left: 1px solid #e7e7e7;
    border-right: 1px solid #e7e7e7;
    border-top: 1px solid #e7e7e7;
    border-radius: 4px !important;
    padding: 0 10px;
    height: 35px !important;
    background-image: none !important; 
  }
  select.form-control:focus{
    outline: none !important;
    box-shadow: none !important;
  }
  .custom-input-icon{
    top: 10px !important;
    right: 10px !important;
    z-index: 3 !important;
  }

  .inputEmail {
    display: flex;
    width: 400px;
  }

  .inputEmail span {
    flex: 1 1 auto;
  }

  .btn-icon {
    height: 30px;
    margin-left: 5px;
    padding: 0 0.6rem;
  }
</style>